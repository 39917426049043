/** @format */

export const AuthActions = {
  SET_AUTH: "SET_AUTH",
  SET_ONLINE: "SET_ONLINE",
  LOG_OUT: "LOG_OUT",
  SET_TAHUN: "SET_TAHUN",
};

export const StoreActions = {
  SET_STORE: "SET_STORE",
  SET_TAHUN_PERIODE_ACTIVE: "SET_TAHUN_PERIODE_ACTIVE",

  GET_LOCAL_STORE: "GET_LOCAL_STORE",
  DELETE_STORE: "DELETE_STORE",
  CLEAR_STORE: "DELETE_STORE",
  CREATE_STORE_LOCAL: "CREATE_STORE_LOCAL",
};

export const BosActions = {
  SET_BOS: "SET_BOS",

  GET_LOCAL_BOS: "GET_LOCAL_BOS",
  DELETE_BOS: "DELETE_BOS",
  CLEAR_BOS: "DELETE_BOS",
  CREATE_BOS_LOCAL: "CREATE_BOS_LOCAL",
};
