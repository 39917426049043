/** @format */
import { BosActions } from "../actionsName";
type bosParams = {
  alokasiSumberDanaBos: any[];
  verifikasiSumberDanaBos: any[];
  pengajuanSumberDanaBos: any[];
  penyaluranSumberDanaBos: any[];
};

const initialBosState: bosParams = {
  alokasiSumberDanaBos: [],
  verifikasiSumberDanaBos: [],
  pengajuanSumberDanaBos: [],
  penyaluranSumberDanaBos: [],
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const bosReducers = (state = initialBosState, action): any => {
  switch (action.type) {
    case BosActions.SET_BOS:
      return {
        ...state,
        ...action.data,
      };

    case BosActions.CLEAR_BOS:
      return initialBosState;
    default:
      return state;
  }
};

export default bosReducers;
