/** @format */

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/css/tailwind.output.css";
import "./assets/css/tailwind.css";
import App from "./App";
import { SidebarProvider } from "./context/SidebarContext";
import ThemedSuspense from "./components/ThemedSuspense";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
import "./assets/css/customstyle.css";
// import * as Sentry from "@sentry/react";
// import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";

import Store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

// Sentry.init({
//   dsn: "https://46f1b42a36d44c17b54b50ba1a3c1cc3@sentry-scrum.impstudio.id/20",
//   integrations: [
//     new Sentry.BrowserTracing(),
//     new ExtraErrorDataIntegration({
//       depth: 10,
//     }),
//   ],

//   enabled: process.env.NODE_ENV !== "development",
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.1,
// });

localStorage.theme = "light";

ReactDOM.render(
  <Suspense fallback={<ThemedSuspense />}>
    <Provider store={Store}>
      <PersistGate persistor={persistor}>
        <SidebarProvider>
          <App />
        </SidebarProvider>
      </PersistGate>
    </Provider>
  </Suspense>,
  document.getElementById("root"),
);

serviceWorker.register();
