/** @format */

import axios, { AxiosError } from "axios";
import { captureException, withScope } from "@sentry/react";
import { Modal } from "antd";

// console.log("DARI API", process.env.REACT_APP_API_URL);

export const baseURL: string =
  window.REACT_APP_API_URL ||
  // "https://api-gateway.erkam-v2.kemenag.go.id/api";
  // "https://api-gateway.erkam-v2.madrasahkeren.com/api";
  // "https://latihan-api-gateway.erkam-v2.kemenag.go.id/api";
  "https://staging-api-gateway.erkam-v2.kemenag.go.id/api";

const api = axios.create({
  baseURL: baseURL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      // "Origin,Accept,DNT,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range,Authorization,x-content-type-options,access-control-allow-origin,access-control-allow-credentials,access-control-allow-headers",
      "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Erkam-Year",
    "Access-Control-Allow-Credentials": true,
  },
});

// request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("auth");
    if (token) {
      config.headers["Authorization"] = "Bearer " + JSON.parse(token).token;
      config.headers["X-Erkam-Year"] =
        JSON.parse(token).isTahun || new Date().getFullYear();
    } else {
      config.headers["X-Erkam-Year"] = new Date().getFullYear();
    }
    return config;
  },
  (error) => {
    if (error.response.status === 400) {
      return error.response.return;
    }
    return Promise.reject(error);
  },
);

const getURLFromAxiosError = (error: AxiosError) => {
  const axiosConfigURL = `${error.config?.baseURL}${error.config?.url}`;

  let url = "No URL";

  if (error?.request?.responseURL) {
    url = error?.request?.responseURL;
  } else if (axiosConfigURL) {
    url = axiosConfigURL;
  }

  return url;
};

const sentryCaptureError = (error: AxiosError) => {
  withScope((scope) => {
    const method = error?.config?.method?.toUpperCase() ?? "No Method";

    const url = getURLFromAxiosError(error);

    scope.setTransactionName(`${method}: ${url}`);
    scope.addBreadcrumb({
      category: "Axios Error",
      level: "error",
      data: {
        rest: {
          method,
          url,
          params: error.config?.params,
        },
        data: {
          responseData: error.response?.data,
          payloadData: error.config?.data,
        },
        token: error.config?.headers.Authorization,
      },
      type: "error",
    });
    scope.setFingerprint([
      "Axios Error",
      method,
      url,
      error.stack ?? "No Stack",
    ]);

    captureException(error);
  });
};

// response interceptor
api.interceptors.response.use(
  (response) => {
    if (!response?.data?.success) {
      //response success : 0 bases
      // throw Error(response.data.meta.success);
      Modal.error({
        title: "Kesalahan",
        content: response?.data?.meta?.success,
        okType: "danger",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
    } else {
      return response;
    }
  },
  (error) => {
    const errorStatusCode = error.response.status;

    if (errorStatusCode === 401) {
      localStorage.clear();
      // notifAlert({ type: "error", description: "Session Habis!!" });
      window.location.href = "/login";

      return Promise.reject(error);
    }

    if (errorStatusCode === 400 || errorStatusCode === 404) {
      Modal.error({
        title: "Kesalahan",
        content: error?.response?.data?.meta?.success,
        okType: "danger",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
    }

    if (errorStatusCode === 500) {
      Modal.error({
        title: "Kesalahan",
        content:
          error.response.data.return ||
          "Sedang memuat data.. Mohon di reload kembali",
        okType: "danger",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
    }

    sentryCaptureError(error);
    return Promise.reject(error);
  },
);

export default api;
